<template>
  <v-container>
    <v-row class="mt-2" justify="center">
      <h2 class="primary--text">Créer un dossier</h2><br>
      <v-progress-circular v-if="loading" color="primary" indeterminate></v-progress-circular>
    </v-row>
    <v-form ref="newDossierForm">
      <v-row class="mb-6">
        <v-col xl="4" cols="12">
          <h4>Informations sur le dossier</h4>
          <v-text-field label="Référence" :rules="[notNullNorEmpty]" v-model="dossier.reference"></v-text-field>
          <v-select label="Instructeur" v-model="dossier.instructeur" :items="instructeurs" item-text="fullname"
                    item-value="id"></v-select>
          <v-select label="Enquêteur" v-model="dossier.enqueteur" :items="enqueteurs" item-text="value"
                    item-value="value"></v-select>
          <v-select label="Statut initial du dossier" :rules="[notNullNorEmpty]" v-model="dossier.statut"
                    :items="statuts"
                    item-text="name" item-value="id"></v-select>
        </v-col>
        <v-col xl="4" cols="12">
          <h4>Informations sur le bénéficiaire</h4>
          <v-select form label="Titre" :rules="[notNullNorEmpty]" v-model="beneficiaire.contact.titre" :items="titre_select" item-text="name"
                    item-value="value"></v-select>
          <v-text-field :rules="[notNullNorEmpty]" label="Nom" v-model="beneficiaire.contact.nom"></v-text-field>
          <v-text-field :rules="[notNullNorEmpty]" label="Prénom" v-model="beneficiaire.contact.prenom"></v-text-field>
          <v-text-field :rules="[notNullNorEmpty]" label="Adresse" v-model="beneficiaire.adresse"></v-text-field>
          <v-select @change="beneficiaire.code_postal = VILLES['Martinique'][beneficiaire.ville]"
                    :rules="[notNullNorEmpty]" :items="villes" item-value="value" item-text="name" v-model="beneficiaire.ville"
                    label="Ville"></v-select>
          <v-select @change="beneficiaire.ville = VILLES['Martinique'][beneficiaire.code_postal]"
                    :items="codes_postaux" item-text="name" item-value="value" v-model="beneficiaire.code_postal"
                    label="Code postal"></v-select>
          <v-text-field label="Profession" v-model="beneficiaire.profession"></v-text-field>
          <v-select :items="situation_matrimoniale_select" item-value="value" item-text="name"
                    v-model="beneficiaire.situation_matrimoniale" label="Statut matrimonial"></v-select>



        </v-col>
        <v-col xl="4" cols="12">
          <h4>Informations sur le bien immobilier</h4>
          <v-select :rules="[notNullNorEmpty]" :items="type_bien_select" item-text="name" item-value="value" label="Type de bien"
                    v-model="bien_immobilier.type_bien"></v-select>
          <v-text-field label="Référence cadastrale" :rules="[notNullNorEmpty]" v-model="bien_immobilier.ref_cadastrale"></v-text-field>
          <v-text-field label="Année de construction" type="number" min="1950" max="2100" step="1"
                        v-model="bien_immobilier.annee_construction"></v-text-field>
          <v-select :items="type_assainissement_select" item-text="name" item-value="value"
                    label="Type d'assainissement" v-model="bien_immobilier.assainissement"></v-select>
          <v-text-field label="Latitude" v-model="bien_immobilier.latitude"></v-text-field>
          <v-text-field label="Longitude" v-model="bien_immobilier.longitude"></v-text-field>
          <v-row justify="center" align="center">
            <v-btn @click="saveGeolocalisation" class="mt-2" color="primary">Utiliser position actuelle comme
              géolocalisation
            </v-btn>
          </v-row>
        </v-col>
        <v-row class="mt-6" justify="center" align="center">
          <v-btn @click="createDossier" :disabled="creating" color="primary">Créer le dossier</v-btn>
          <v-progress-circular color="primary" indeterminate v-if="creating"></v-progress-circular>
        </v-row>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import {convertSelectModelToSelct, convertStringListToSelect, convertToSelect} from "@/util";
import {SEXE} from "@/constants/Beneficiaire";
import {VILLES} from "@/constants/Villes";
import {TYPES_PPRN} from "@/constants/BienImmobilier";
import {ValidationRulesMixin} from "@/mixins/ValidationRulesMixin";

function addLeadingZeros(number) {
  return number < 10 ? `0${number}` : number.toString();
}

export default {
  name: "NewDossier",
  mixins: [ValidationRulesMixin],
  async mounted() {
    this.loading = true;
    let currentDay = dayjs();
    this.dossier.reference = `AAH-${currentDay.year()}-${addLeadingZeros(currentDay.month() -1)}-${addLeadingZeros(currentDay.date())}`;
    let statusFetchStatus = this.$store.getters["dossier/statuts/statut_fetch_status"];
    if (statusFetchStatus === "INITIAL" || statusFetchStatus === "ERROR") {
      await this.$store.dispatch("dossier/statuts/getStatuts");
    }
    let userFetchStatus = this.$store.getters["users/loading_status"];
    if (userFetchStatus === "INITIAL" || userFetchStatus === "ERROR") {
      await this.$store.dispatch("users/fetchUsers");
    }
    let selectFetchStatus = this.$store.getters["select/fetch_status"];
    if (selectFetchStatus === "INITIAL" || selectFetchStatus === "ERROR") {
      await this.$store.dispatch("select/fetchSelects");
    }
    this.dossier.instructeur = this.logged_user !== null ? this.logged_user.group === 'Instructeur' ? this.logged_user.id : null : null;
    this.dossier.enqueteur = this.logged_user !== null ? this.logged_user.group === 'Enquêteur' ? this.logged_user.id : null : null;
    this.dossier.statut = this.statuts.find((statut) => statut.numerical_value === 0)?.id || null;
    this.loading = false;
  },
  data() {
    return {
      VILLES,
      creating: false,
      loading: false,
      dossier: {
        "reference": "",
        "instructeur": null,
        "enqueteur": null,
        "etat": "",
        "statut": null
      },
      beneficiaire: {
        "contact": {
          "nom": "",
          "prenom": "",
          "titre": "",
          "email": "",
          "tel_mobile": "",
          "tel_fixe": ""
        },
        "relation_referent": null,
        "situation_matrimoniale": null,
        "profession": "",
        "adresse": "",
        "ville": "",
        "code_postal": null,
        "date_naissance": null,
        "lieu_naissance": "",
        "numero_secu": "",
        "handicap": false,
        "epargne": null,
        "referent": null,
        "dossier": null
      },
      bien_immobilier: {
        "type_bien": "",
        "ref_cadastrale": "",
        "pprn": null,
        "zone_plu": "",
        "surface_brute": null,
        "surface_plancher": null,
        "surface_habitable": null,
        "annee_construction": null,
        "nbre_etage": "",
        "nbre_pieces": null,
        "assainissement": null,
        "proprio_terrain": false,
        "proprio_logement": false,
        "dossier": null,
        "latitude": null,
        "longitude": null
      }
    }
  },
  computed: {
    logged_user() {
      return this.$store.getters["auth/getLoggedUser"];
    },
    statuts() {
      return this.$store.getters["dossier/statuts/statuts"];
    },
    instructeurs() {
      return this.$store.getters["users/instructeurs"];
    },
    enqueteurs() {
      return this.$store.getters["select/enqueteurs"];
    },
    sexe_select() {
      return convertToSelect(SEXE);
    },
    titre_select() {
      return convertSelectModelToSelct(this.$store.getters["select/titres"])
    },
    situation_matrimoniale_select() {
      return convertSelectModelToSelct(this.$store.getters["select/statuts_matrimoniaux"])
    },
    codes_postaux() {
      return convertStringListToSelect(Object.values(VILLES["Martinique"]));
    },
    villes() {
      return convertStringListToSelect(Object.keys(VILLES["Martinique"]));
    },
    type_assainissement_select() {
      return convertSelectModelToSelct(this.$store.getters["select/types_assainissement"])
    },
    type_bien_select() {
      return convertSelectModelToSelct(this.$store.getters["select/types_biens"])
    },
    nbre_etage_select() {
      return convertSelectModelToSelct(this.$store.getters["select/nombre_etage"])
    },
    type_pprn_select() {
      return convertToSelect(TYPES_PPRN);
    }
  },
  methods: {
    async createDossier() {
      let validate = this.$refs.newDossierForm.validate();
      if (validate) {
        this.creating = true;
        let prenom = this.beneficiaire.contact.prenom
        this.beneficiaire.contact.prenom = prenom.charAt(0).toUpperCase() + prenom.slice(1);

        let nom = this.beneficiaire.contact.nom;
        this.beneficiaire.contact.nom = nom.toUpperCase();
        let result = await this.$store.dispatch("dossier/postDossier", this.dossier);
        if (result !== "success") {
          this.$store.dispatch("annonce/annonceError", "Une erreur est survenue lors de la création du dossier");
          this.creating = false;
          return;
        }
        let created_dossier = this.$store.getters["dossier/getSelectedDossier"];
        console.log(`Created dossier: #${created_dossier.id}`)
        this.beneficiaire = {...this.beneficiaire, dossier: created_dossier.id}
        this.bien_immobilier = {...this.bien_immobilier, dossier: created_dossier.id}
        let resultBeneficiaire = await this.$store.dispatch("beneficiaire/postBeneficiaire", this.beneficiaire);
        let resultBienImmobilier = await this.$store.dispatch("bien_immobilier/postBienImmobilier", this.bien_immobilier);
        if (resultBeneficiaire === null || resultBienImmobilier === null) {
          this.$store.dispatch("annonce/annonceWarning", "Une erreur est survenue lors du bien immobilier " +
              "et du bénéficiaire, vérifiez les informations dans la fiche du dossier créé.");
          await this.$router.push("/dossier");
          this.creating = false;
          return;
        }
        await this.$store.dispatch("annonce/annonceSuccess", "Dossier créé.")
        await this.$router.push(`/dossier/${created_dossier.id}`);
      }

    },
    async saveGeolocalisation() {
      let that = this;
      navigator.geolocation.getCurrentPosition(async (position) => {
        that.bien_immobilier["latitude"] = position.coords.latitude;
        that.bien_immobilier["longitude"] = position.coords.longitude;
      }, () => {
        that.$store.dispatch("annonce/annonceError", "Une erreur est survenue lors de la récupération de la géolocalisation.")
      });
   },
  }

}
</script>

<style scoped>

</style>