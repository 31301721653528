export const TYPES_PPRN = {
    "rouge": "rouge",
    "orange": "orange",
    "vert": "vert",
    "jaune": "jaune",
    "rose": "rose",
    "gris": "gris",
    "marron": "marron",
    "bleu": "bleu",
    "violet": "violet",
    "bleu zébré": "bleu zébré",
    "marron zébré": "marron zébré",
}
export const TYPE_ASSAINISSEMENT = {
    "collectif": "collectif",
    "individuel": "individuel"
};
