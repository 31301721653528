export const VILLES = {
    "Martinique": {
        "Fort-de-France": 97200,
        "Basse-Pointe": 97218,
        "Bellefontaine": 97222,
        "Case-Pilote": 97222,
        "Ducos": 97224,
        "Fonds-Saint-Denis": 97250,
        "Grand'Rivière": 97218,
        "Gros-Morne": 97213,
        "L'Ajoupa-Bouillon": 97216,
        "La Trinité": 97220,
        "Le Carbet": 97221,
        "Le Diamant": 97223,
        "Le François": 97240,
        "Le Lamentin": 97232,
        "Le Lorrain": 97214,
        "Le Marigot": 97225,
        "Le Marin": 97290,
        "Le Morne-Rouge": 97260,
        "Le Morne-Vert": 97226,
        "Le Prêcheur": 97250,
        "Le Robert": 97231,
        "Le Vauclin": 97280,
        "Les Anses-d'Arlet": 97217,
        "Les Trois-Îlets": 97229,
        "Macouba": 97218,
        "Rivière-Pilote": 97211,
        "Rivière-Salée": 97215,
        "Saint-Esprit": 97270,
        "Saint-Joseph": 97212,
        "Saint-Pierre": 97250,
        "Sainte-Anne": 97227,
        "Sainte-Luce": 97228,
        "Sainte-Marie": 97230,
        "Schoelcher": 97233,
    }
}